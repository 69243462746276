import cryptojs from 'crypto-js'

const key = process.env.VUE_APP_SECRET

export const encrypt = (content) => {
  let result = ''
  try {
    result = cryptojs.AES.encrypt(JSON.stringify(content), key).toString()
  } catch (error) {
    console.log('encrypt', error, content, key)
  }
  return result

}
export const decrypt = (crypted) => {
  let result = ''
  try {
    result = JSON.parse(cryptojs.AES.decrypt(crypted, key).toString(cryptojs.enc.Utf8))
  } catch (error) {
    console.log('decrypt', error, crypted, key)
  }
  return result
}