<template>
  <v-container
    v-touch="{
      left: () => nextDay(),
      right: () => prevDay(),
    }"
    class="h-100"
  >
    <v-sheet
      tile
      class="d-flex justify-content mt-1"
    >
      <v-btn
        fab
        small
        outlined
        class="mt-0"
        color="rgba(0, 0, 0, 0.4)"
        @click="prevDay"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <day-picker
        :key="componentKey"
        :day="date"
        :outlined="true"
        label="Datum"
        :readonly="true"
        :heute="false"
        @changed="dateChanged"
      />
      <v-btn
        fab
        small
        outlined
        class="mt-0"
        color="rgba(0, 0, 0, 0.4)"
        @click="nextDay"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet v-show="!loading">
      <day-part
        v-if="morgens.length > 0"
        :data="morgens"
        :day="date"
        icon="mdi-weather-sunset-up"
        :title="Object.keys({ morgens })[0]"
        :shift="1"
        :loading="loading"
        @changed="reload"
      />
      <day-part
        v-if="mittags.length > 0"
        :data="mittags"
        :day="date"
        icon="mdi-weather-sunny"
        :title="Object.keys({ mittags })[0]"
        :shift="2"
        :loading="loading"
        @changed="reload"
      />
      <day-part
        v-if="abends.length > 0"
        :data="abends"
        :day="date"
        icon="mdi-weather-night"
        :title="Object.keys({ abends })[0]"
        :shift="3"
        :loading="loading"
        @changed="reload"
      />
      <day-flash
        v-if="flashEnabled"
        :data="flashstats"
        :day="date"
        icon="mdi-head-flash-outline"
        title="Tagebuch"
        :loading="loading"
        @changed="reload"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import DayPart from '@/components/DayPart'
import api from '@/api/medix'
import api2 from '@/api/flash'
import DayPicker from '@/components/DayPicker'
import DayFlash from '@/components/DayFlash.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { DayPart, DayPicker, DayFlash },
  data: function () {
    return {
      loading: true,
      date:
        this.$store.state.currentDay ||
        new Date().toISOString().substring(0, 10),
      morgens: [],
      mittags: [],
      abends: [],
      flashstats: [],
      componentKey: 0,
    }
  },
  computed: {
    ...mapFields([
      'flashRef',
      'currentDay',
      'flashEnabled'
    ]),
  },
  watch: {
    flashEnabled() {
      this.reload()
    }
  },
  async mounted() {
    //console.log(this.$route.query.today)
    if (this.$route.query.today === 'true') {
      //console.log('today')
      this.date = new Date().toISOString().substring(0, 10)
    }
    this.currentDay = this.date
    this.reload()
  },
  methods: {
    dateChanged(val) {
      ////console.log('emitted', val)
      this.date = val
      this.currentDay = val
      ////console.log('new date', this.date)
      this.reload()
    },
    async reload() {
      ////console.log('reloading', this.date)
      this.forceRerender()
      let ref = await api2.GetAll()
      this.flashRef = ref.map(i =>
      {
        // eslint-disable-next-line no-unused-vars
        const [first, ...rest] = i.Name.split(' ')
        return { ...i, Name: first }
      })
      this.morgens = await api.GetShift(1, this.date)
      this.mittags = await api.GetShift(2, this.date)
      this.abends = await api.GetShift(3, this.date)
      let result = await api2.GetFlashsByDay(this.date)
      if (result && result.length > 0) {
        this.flashstats = result.map(i =>
          (
            { ...i, Day: i.Day.substring(0, 10) }
          ))
      } else {
        this.flashstats = []
      }
      this.loading = false
    },
    prevDay() {
      this.loading = true
      let prev = new Date(this.date)
      prev.setDate(prev.getDate() - 1)
      this.date = prev.toISOString().substring(0, 10)
      this.currentDay = this.date
      this.forceRerender()
      this.reload()
    },
    nextDay() {
      this.loading = true
      let next = new Date(this.date)
      next.setDate(next.getDate() + 1)
      this.date = next.toISOString().substring(0, 10)
      this.currentDay = this.date
      this.forceRerender()
      this.reload()
    },
    forceRerender() {
      this.componentKey += 1
      ////console.log('rerendered')
    },
  },
}
</script>

<style lang="scss" scoped></style>
