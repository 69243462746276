<template>
  <v-container>
    <med-card :data="meds" />
  </v-container>
</template>

<script>
import MedCard from '@/components/MedCard.vue'
import api from '@/api/medix'

export default {
  components: { MedCard },
  data: function () {
    return {
      meds: [],
      disabled: true,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.meds = await api.GetAll()
    },
  },
}
</script>

<style></style>
