<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        <span class="d-flex">
          <v-text-field
            v-model="flash.Short"
            label="Name"
            placeholder="Name"
            class="grow"
          />
          <v-text-field
            v-model="flash.Color"
            label="Farbe"
            placeholder="#FFFFFF"
            class="ml-4"
          />
          <v-icon
            class="ml-n6"
            :color="flash.Color"
          >mdi-square</v-icon>
        </span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field
          v-model="flash.Beschreibung"
          label="Beschreibung"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          to="/flashs"
        >
          zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text>
          löschen
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/api/flash'

export default {
  data: () => ({
    flash: {
      Id: 0,
      UserId: 0,
      Name: '',
      Color: '#000',
    },
    componentKey: 0,
  }),
  mounted() {
    //console.log('before', this.flash)
    this.getFlash()
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'SET_SNACKBAR_TEXT']),
    async getFlash() {
      let flashID = this.$route.params.id
      if (flashID > 0) {
        this.flash = await api.GetSingle(flashID)
        this.flash.Beschreibung = this.description(this.flash)
        this.flash.Short = this.shortcut(this.flash)
        this.forceRerender()
        //console.log('after', this.flash)
      } else {
        this.flash = {
          Id: 0,
          UserId: 0,
          Name: '',
          Beschreibung: '',
          Short: '',
          Color: '#000',
        }
        this.flash.UserId = parseInt(this.$store.state.auth.user_id)
        //console.log('after and new', this.flash)
      }
    },
    shortcut(item) {
      let sc = item.Name.split(' ')
      return sc[0]
    },
    description(item) {
      let desc = item.Name.split(' ')
      // eslint-disable-next-line no-unused-vars
      const [first, ...rest] = desc
      return rest.join(' ')
    },
    firstLetter(item) {
      return (
        'mdi-alpha-' + item.Name.charAt(0).toLowerCase() + '-circle-outline'
      )
    },
    async save() {
      //console.log(this.flash)
      try {
        this.flash.Name = this.flash.Short + ' ' + this.flash.Beschreibung
        await api.ChangeFlash(this.flash)
        //console.log(result)
        this.$router.push('/flashs')
      } catch (error) {
        //console.log(error)
        this.SET_SNACKBAR(true)
        this.SET_SNACKBAR_TEXT(error.title)
      }
    },
    forceRerender() {
      this.componentKey += 1
      //console.log('rerendered')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
::v-deep #radio .v-input--selection-controls__input {
  margin-right: 1px;
}
::v-deep .v-card__title {
  background-image: linear-gradient(135deg, rgb(229, 229, 229, 0.8), #fff);
}
.v-radio {
  margin-right: 8px !important;
}
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
