import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Login from '@/views/Login'
import Calendar from '@/views/Calendar'
import Form from '@/views/Form'
import Form2 from '@/views/Form2'
import Day from '@/views/Day'
import Meds from '@/views/Meds'
import Flashs from '@/views/Flashs'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Login'
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Calendar'
      }
    },
    {
      path: '/day',
      name: 'day',
      component: Day,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Day'
      }
    },
    {
      path: '/add/:id',
      name: 'add',
      component: Form,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Form'
      }
    },
    {
      path: '/add2/:id',
      name: 'add2',
      component: Form2,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Form2'
      }
    },
    {
      path: '/form/:id',
      name: 'form',
      component: Form,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Form'
      }
    },
    {
      path: '/form2/:id',
      name: 'form2',
      component: Form2,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Form2'
      }
    },
    {
      path: '/meds',
      name: 'meds',
      component: Meds,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Meds'
      }
    },
    {
      path: '/flashs',
      name: 'flashs',
      component: Flashs,
      meta: {
        requiresAuth: false,
        breadcrumb: 'Flashs'
      }
    }
  ],
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
})

router.beforeEach((to, from, next) => {
  console.log('hello from router', from, to)
  if (to.name === 'login') {
    store.commit('SET_APPBAR', false)
    store.commit('auth/UNSET_USER')
  }
  else {
    store.commit('SET_APPBAR', true)
  }
  if (!store.state.auth.token && to.name !== 'login') {
    console.log('no  auth')
    store.commit('SET_APPBAR', false)
    next('/')
  } else {
    next()
  }
})

export default router
