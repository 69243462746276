import axios from 'axios'
import store from '@/store'
import { decrypt } from '@/services/crypto'

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'Access-Control-Allow-Credentials': true,
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  }
})

instance.interceptors.request.use(
  config => {
    const token = decrypt(localStorage.getItem('access_token'))
    if (token) {
      config.headers.common['Authorization'] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  async function (error) {
    const originalRequestConfig = error.config
    if (error.response.status) {
      switch (error.response.status) {
      case 400:
        console.warn('bad request')
        break
      case 401:
        console.warn('session expired')
        await store.dispatch('auth/refreshToken')
        originalRequestConfig.headers.Authorization = decrypt(localStorage.getItem('access_token'))
        console.log('retry last request')
        return axios(originalRequestConfig)
      case 403:
        console.warn('forbidden')
        break
      case 404:
        console.warn('page not exist')
        break
      case 502:
      }
      console.log('reject')
      return Promise.reject(error.response)
    } else {
      console.log('network error')
      return Promise.reject(error.message)
    }
  }
)

export default instance