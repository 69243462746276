import client from '@/api/rest.client'

export default {
  async authenticate (Username, Password) {
    try {
      let result = await client.post('/users/authenticate', JSON.stringify({ Username, Password }))
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async refreshAccessToken () {
    try {
      let result = await client.post('/users/refresh-token')
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  }
}
