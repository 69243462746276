import client from '@/api/rest.client'
import store from '@/store'

export default {
  async GetAll () {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/user/' + userId)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetSingle (id) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/user/' + userId + '/med/' + id)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetShift (id, day) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/shift/' + userId + '/' + id + '/' + day)
      //console.log(result)
      return result.data
    } catch (error) {
      console.log(error)
      return Promise.reject(error.data)
    }
  },

  async GetMonthGrouped (day) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/calendar/' + userId + '/' + day)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetMonthWithDose (day) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/calendar/dose/' + userId + '/' + day)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async ChangeMedixStat (obj) {
    try {
      let result = await client.post('/medixs/stats/upsert', obj)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async ChangeMedix (obj) {
    try {
      let result = await client.post('/medixs/meds/upsert', obj)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  }
}