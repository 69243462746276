/**
 * dto_flash
 * @date 2022-09-16
 * @param {number} Id
 * @param {number} UserId
 * @param {string}  Day
 * @param {number}  Type
 * @param {number}  Intens 
 * @returns {object}
 */
export default function dto_flash (Id = 0, UserId, Day, Type = 1, Intens = 1) {
    return {
        Id,
        UserId,
        Day,
        Type,
        Intens
    }
}
