import client from '@/api/rest.client'
import store from '@/store'

export default {
  async GetAll () {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('medixs/user/flash/' + userId)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetSingle (id) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/user/' + userId + '/flash/' + id)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetFlashsByDay (day) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/flashs/' + userId + '/' + day)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async GetMonthGrouped (day) {
    try {
      let userId = store.state.auth.user_id
      let result = await client.get('/medixs/calendar/flashs/' + userId + '/' + day)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async ChangeFlashStat (obj) {
    try {
      let result = await client.post('/medixs/flashstat/upsert', obj)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async ChangeFlash (obj) {
    try {
      let result = await client.post('/medixs/flash/upsert', obj)
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

  async DeleteFlashStat (obj) {
    try {
      let result = await client.request({
        url: '/medixs/flashstat/delete',
        method: 'delete',
        data: obj
      })
      return result.data
    } catch (error) {
      return Promise.reject(error.data)
    }
  },

}