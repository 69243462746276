<template>
  <div class="text-center">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          small
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :color="color">
            {{ dose(value) }}
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.id"
          @click="clicked(item.id)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DoseSelect',
  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'color'],
  data: function () {
    return {
      items: [
        { id: 4, icon: 'mdi-circle-slice-2', text: 'viertel' },
        { id: 2, icon: 'mdi-circle-slice-4', text: 'halbe' },
        { id: 1, icon: 'mdi-circle-slice-8', text: 'eine' },
        { id: 14, icon: 'mdi-hexagon-slice-2', text: 'eineinviertel' },
        { id: 12, icon: 'mdi-hexagon-slice-3', text: 'eineinhalb' },
        { id: 11, icon: 'mdi-hexagon-slice-6', text: 'zwei' },
      ],
    }
  },
  methods: {
    clicked(val) {
      this.$emit('changed', val)
    },
    dose(d) {
      if (d === 1) return 'mdi-circle-slice-8'
      if (d === 2) return 'mdi-circle-slice-4'
      if (d === 4) return 'mdi-circle-slice-2'
      if (d === 11) return 'mdi-hexagon-slice-6'
      if (d === 12) return 'mdi-hexagon-slice-3'
      if (d === 14) return 'mdi-hexagon-slice-2'
      return 'mdi-circle-outline'
    },
  },
}
</script>

<style></style>
