/**
 * dto_medixstat
 * @date 2022-03-04
 * @param {number} Id
 * @param {number} UserId
 * @param {string}  Day
 * @param {number}  MedId
 * @param {number}  ShiftId
 * @param {number}  Dose
 * @param {number}  Status
 * @returns {object}
 */
export default function dto_medixstat (Id, UserId, Day, MedId, ShiftId, Dose, Status,) {
    return {
        Id,
        UserId,
        Day,
        MedId,
        ShiftId,
        Dose,
        Status,
    }
}
