<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="0"
    :nudge-top="-40"
    transition="scale-transition"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        :readonly="readonly"
        :outlined="outlined"
        :placeholder="placeholder"
        hide-details
        class="mb-4 mx-2"
        v-bind="attrs"
        dense
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      locale="de-de"
      first-day-of-week="1"
      show-current
      show-week
      @change="dateChanged"
    >
      <template v-if="heute">
        <v-spacer />
        <v-btn
          text
          @click="iconClick()"
        >
          heute
        </v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DayPicker',
  props: ['day', 'outlined', 'readonly', 'label', 'placeholder', 'heute'],
  data: function () {
    return {
      menu: false,
      date: this.day,
    }
  },
  computed: {
    computedDateFormatted: {
      get() {
        ////console.log('get', this.value)
        return this.formatDate(this.date)
      },
      set(value) {
        this.date = this.parseDate(value)
      },
    },
  },
  methods: {
    dateChanged() {
      //console.log('dateChanged', this.date)
      this.menu = false
      this.$emit('changed', this.date)
    },
    formatDate(d) {
      if (!d) return null
      ////console.log('format', d)
      const [year, month, day] = d.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(d) {
      if (!d) return null
      ////console.log('parse', d)
      const [month, day, year] = d.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    iconClick() {
      ////console.log('inner icon click')
      this.date = new Date().toISOString().substring(0, 10)
    },
  },
}
</script>

<style></style>
