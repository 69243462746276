<template>
  <div>
    <draggable
      :disabled="disabled"
      :list="currentList"
      @end="onEnd"
    >
      <v-card
        v-for="item in currentList"
        :key="item.Id"
        class="mb-4"
        :to="link(item.Id)"
        elevation="0"
      >
        <v-card-title>
          <v-icon
            class="mr-2 mt-1"
            :color="item.Color"
          >
            {{ firstLetter(item) }}
          </v-icon>
          {{ shortcut(item) }}
          <v-icon
            v-if="item.Alert"
            color="red"
            class="ml-2 mt-1"
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-spacer />
          <v-icon
            v-if="item.Pause"
            color="black"
            class="ml-2 mt-1"
          >
            mdi-pause-circle-outline
          </v-icon>
          <v-icon
            v-if="finished(item.To)"
            color="black"
            class="ml-2 mt-1"
          >
            mdi-flag-checkered
          </v-icon>
        </v-card-title>
        <v-card-text class="d-flex pb-0">
          <v-text-field :value="description(item)" />
        </v-card-text>
      </v-card>
    </draggable>
    <v-btn
      fab
      fixed
      bottom
      right
      small
      color="primary"
      dark
      @click="disabled = !disabled"
    >
      <v-icon
        v-if="disabled"
        dark
      >
        mdi-sort-variant-lock
      </v-icon>
      <v-icon
        v-else
        dark
      >
        mdi-arrow-all
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'FlashCard',
  components: {
    draggable,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        []
      },
    },
  },
  data() {
    return {
      disabled: true,
    }
  },
  computed: {
    currentList() {
      return this.data.map((obj) => ({ ...obj, Order: 0 }))
    },
  },
  mounted() {
    console.log(this.currentList)
  },
  methods: {
    link(id) {
      return '/form2/' + id
    },
    dose(d) {
      if (d === 1) return 'mdi-circle-slice-8'
      if (d === 2) return 'mdi-circle-slice-4'
      if (d === 4) return 'mdi-circle-slice-2'
      if (d === 11) return 'mdi-hexagon-slice-6'
      if (d === 12) return 'mdi-hexagon-slice-3'
      if (d === 14) return 'mdi-hexagon-slice-2'
      return 'mdi-circle-outline'
    },
    finished(date) {
      let end = new Date(date)
      let today = new Date()
      return end < today ? true : false
    },
    shortcut(item) {
      let sc = item.Name.split(' ')
      return sc[0]
    },
    description(item) {
      let desc = item.Name.split(' ')
      // eslint-disable-next-line no-unused-vars
      const [first, ...rest] = desc
      return rest.join(' ')
    },
    firstLetter(item) {
      return (
        'mdi-alpha-' + item.Name.charAt(0).toLowerCase() + '-circle-outline'
      )
    },
    onEnd() {
      this.currentList.forEach((medix, i) => {
        medix.Order = i
      })
      console.log(this.currentList)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  background-image: linear-gradient(135deg, rgb(229, 229, 229, 0.8), #fff);
}
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
::v-deep .v-label {
  font-size: 14px;
}
</style>
