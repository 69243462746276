<template>
  <v-form>
    <h4>
      <v-icon color="black">
        {{ icon }}
      </v-icon>
      {{ title }}
    </h4>
    <v-alert
      text
      outlined
      :type="alertType"
      border="left"
      prominent
    >
      <v-switch
        v-for="item in data"
        :key="item.pid"
        v-model="item.Status"
        class="v-input--reverse v-input--expand"
        hide-details
        inset
        dense
        @change="changeState(item)"
      >
        <template #label>
          <dose-select
            :value="item.Dose"
            :color="item.Color"
            @changed="changed($event, item)"
          />
          {{ item.Name }}
          <v-icon
            v-if="attention(item)"
            color="red"
            class="ml-1"
          >
            mdi-alert-circle-outline
          </v-icon>
        </template>
      </v-switch>
    </v-alert>
  </v-form>
</template>

<script>
import api from '@/api/medix'
import dtoMedix from '@/models/dtos/dto_medixstat'
import DoseSelect from '@/components/DoseSelect'

export default {
  name: 'DayPart',
  components: { DoseSelect },
  props: {
    data: {
      type: Array,
      default: () => {
        []
      },
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    day: {
      type: String,
      default: '',
    },
    shift: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    allDone() {
      return this.data.find((x) => x.Status === false) ? false : true
    },
    missedImportant() {
      return this.data.find((x) => x.Status === false && x.Alert === true)
        ? true
        : false
    },
    alertType() {
      ////console.log(this.title)
      if (this.isPast && this.missedImportant) return 'error'
      if (this.allDone) return 'success'
      return 'info'
    },
    isPast() {
      // //console.log(
      //   'isDateBeforeToday',
      //   this.isDateBeforeToday(this.day),
      //   this.day
      // )
      // //console.log('isDateAfterToday', this.isDateAfterToday(this.day), this.day)
      if (this.isDateBeforeToday(this.day)) return true
      if (this.isDateAfterToday(this.day)) return false
      let tod = 0
      switch (this.title) {
      case 'morgens':
        tod = 10
        break
      case 'mittags':
        tod = 14
        break
      case 'abends':
        tod = 22
        break
      }
      let now = new Date().getHours()
      //console.log(now)
      return now < tod ? false : true
    },
  },
  methods: {
    async changeState(item) {
      ////console.log(item)
      let medix = dtoMedix(
        item.Id,
        this.$store.state.auth.user_id,
        this.day,
        item.MedId,
        this.shift,
        item.Dose,
        item.Status
      )
      //console.log(medix)
      await api.ChangeMedixStat(medix)
      this.$emit('changed')
    },
    isDateBeforeToday(date) {
      const today = new Date()
      today.setDate(today.getDate() - 1)
      let day = new Date(date)
      //console.log('isDateBeforeToday', day, today)
      return day < today
    },
    isDateAfterToday(date) {
      const today = new Date()
      today.setDate(today.getDate())
      let day = new Date(date)
      //console.log('isDateAfterToday', day, today)
      return day > today
    },
    attention(item) {
      if (item.Status === false && item.Alert === true) return true
      return false
    },
    changed(event, item) {
      //console.log(event)
      //console.log(item)
      item.Dose = event
      this.changeState(item)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--reverse {
  margin-top: 6px;
  .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .v-application--is-ltr & {
      .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
::v-deep .v-alert__content {
  padding: 0px 0px 10px 20px;
}
::v-deep .v-alert__icon {
  margin: 0px;
  min-width: 24px;
  height: 24px;
  &.v-icon {
    font-size: 24px;
  }
}
::v-deep .v-label {
  font-size: 14px;
}
</style>
