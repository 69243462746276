import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import './registerServiceWorker'
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue)


require('@/assets/css/app.css')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeMount () {
    this.$OneSignal.init({ appId: '7d6af847-a183-4d33-928f-dd3de2f193d2' })
  }
}).$mount('#app')
