import colors from 'vuetify/lib/util/colors'

export const primProf = () => ({
  primary: colors.teal,
  secondary: colors.teal.lighten4,
  accent: colors.teal.lighten3,
})

export const secProf = () => ({
  primary: colors.orange,
  secondary: colors.orange.lighten4,
  accent: colors.orange.lighten3,
})
