const menupoints = {
  items: [
    {
      id: 1,
      title: 'Tages Ansicht',
      icon: 'mdi-calendar-today',
      link: '/day?today=true'
    },
    {
      id: 2,
      title: 'Monats Übersicht',
      icon: 'mdi-calendar-month',
      link: '/calendar'
    },
    {
      id: 3,
      title: 'Medikamenten Liste',
      icon: 'mdi-format-list-text',
      link: '/meds',
    },
    {
      id: 4,
      title: 'Tagebuch Liste',
      icon: 'mdi-format-list-bulleted-type',
      link: '/flashs',
    },
    {
      id: 5,
      title: 'Neues Medikament',
      icon: 'mdi-plus-circle',
      link: '/add/0',
    },
    {
      id: 6,
      title: 'Neuer Tagebucheintrag',
      icon: 'mdi-plus-circle-outline',
      link: '/add2/0',
    },
    {
      id: 7,
      title: 'Abmelden/Wechseln',
      icon: 'mdi-logout-variant',
      link: '/',
    },
  ]
}

export default menupoints
