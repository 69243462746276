<template>
  <v-row
    v-if="true"
    class="px-0 flash-item"
  >
    <v-col
      cols="1"
      class="ml-n4"
    >
      <v-checkbox
        v-model="item.selected"
        dense
        hide-details
        @change="inputChanged"
      ></v-checkbox>
    </v-col>
    <v-col cols="5">
      <v-select
        v-model="item.Type"
        :items="flashRef"
        item-text="Name"
        item-value="Id"
        dense
        hide-details
        class="pl-2"
        @change="inputChanged"
      >
      </v-select>
    </v-col>
    <v-col
      clos="6"
      class="ml-n2"
    >
      <v-slider
        v-model="item.Intens"
        :tick-labels="intens"
        min="1"
        max="3"
        ticks="always"
        tick-size="3"
        :color="color"
        :track-color="color"
        dense
        hide-details
        @change="inputChanged"
      >
      </v-slider>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Flash',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      intens: ['leicht', 'erheblich', 'stark'],
      item: { ...this.data, selected: false },
    }
  },
  computed: {
    ...mapFields(['flashRef']),
    color() {
      let c = 'green'
      switch (this.item.Intens) {
      case 1:
        c = 'lime'
        break
      case 2:
        c = 'orange'
        break
      case 3:
        c = 'red'
        break
      default:
        c = 'green'
      }
      return c
    },
  },
  watch: {
    data() {
      this.items = { ...this.data, selected: false }
    },
  },
  methods: {
    inputChanged() {
      //console.log(this.item)
      this.$emit('data:change', this.item)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-slider__tick-label {
  font-size: x-small;
}

// .flash-item:hover {
//   opacity: 0.5;
// }
</style>
>
