<template>
  <v-container>
    <flash-card :data="flashs" />
  </v-container>
</template>

<script>
import FlashCard from '@/components/FlashCard.vue'
import api2 from '@/api/flash'

export default {
  components: { FlashCard },
  data: function () {
    return {
      flashs: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.flashs = await api2.GetAll()
    },
  },
}
</script>

<style></style>
