import authApi from '@/api/auth'
import { encrypt, decrypt } from '@/services/crypto'
import jwt_decode from 'jwt-decode'
import router from '@/router'
import { getField, updateField } from 'vuex-map-fields'

const state = {
  token: decrypt(localStorage.getItem('access_token')),
  expires: 0,
  retry: 0,
  user_id: decrypt(localStorage.getItem('user_id')) || -1,
  user_name: decrypt(localStorage.getItem('user_name')) || 'Unknown',
  profiles: decrypt(localStorage.getItem('user_profiles')) || []
}

const getters = {
  getToken: state => {
    return state.token
  },
  getField,
}

const actions = {
  async login (context, { user, password }) {
    try {
      let result = await authApi.authenticate(user, password)
      console.log('auth result', result)
      context.commit('SET_USER', result)
      return Promise.resolve(result)
    } catch (error) {
      console.log('auth error', error)
      context.commit('UNSET_USER')
      return Promise.reject(error)
    }
  },

  async refreshToken (context) {
    try {
      let result = await authApi.refreshAccessToken()
      context.commit('SET_USER', result)
      console.log('token refreshed')
      return Promise.resolve(result)
    } catch (error) {
      console.log('auth error', error)
      context.commit('UNSET_USER')
      router.replace('/').catch(() => { })
      return Promise.reject(error)
    }
    finally {
      context.commit('UNSET_RETRY')
    }
  }
}

const mutations = {
  SET_USER (state, payload) {
    state.user_id = payload.Id
    state.user_name = payload.Username
    state.profiles = payload.Profiles
    localStorage.setItem('access_token', encrypt(payload.JwtToken))
    localStorage.setItem('user_id', encrypt(payload.Id))
    localStorage.setItem('user_name', encrypt(payload.Username))
    localStorage.setItem('user_profiles', encrypt(payload.Profiles))
    state.token = payload.JwtToken
    const decodedToken = jwt_decode(payload.JwtToken)
    //console.log(decodedToken)
    if (decodedToken) {
      state.expires = decodedToken.exp
    }
  },

  UNSET_USER (state) {
    state.user_id = -1
    state.user_name = ''
    state.profiles = []
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_name')
    localStorage.removeItem('user_profiles')
    localStorage.removeItem('color_prof')
    state.token = ''
    state.expires = 0
  },

  SET_RETRY (state) {
    state.retry = 1
  },

  UNSET_RETRY (state) {
    state.retry = 0
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
