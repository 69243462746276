<template>
  <v-form>
    <h4>
      <v-icon color="black">
        {{ icon }}
      </v-icon>
      {{ title }}
    </h4>
    <v-alert
      text
      outlined
      border="left"
      prominent
    >
      <v-row class="px-4 pt-4 pb-1">
        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedItems.length > 0"
          icon
          small
          outlined
          color="grey"
          class="mr-2"
          @click.stop="removeFlashItem"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          outlined
          color="grey"
          @click.stop="addFlashItem"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-row>
      <template v-if="flashItems.length > 0">
        <flash
          v-for="item in flashItems"
          :key="item.Id"
          :data="item"
          @data:change="changed"
        ></flash>
      </template>
    </v-alert>
  </v-form>
</template>

<script>
import Flash from '@/components/Flash'
import dtoFlash from '@/models/dtos/dto_flash'
import api2 from '@/api/flash'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DayFlash',
  components: { Flash },
  props: {
    data: {
      type: Array,
      default: () => {
        []
      },
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      /**
      @type {[dtoFlash]}
      */
      flashItems: [],
      selectedItems: [],
    }
  },
  computed: {
    ...mapFields(['currentDay']),
  },
  watch: {
    data() {
      this.flashItems = this.data
    },
  },
  methods: {
    async addFlashItem() {
      let item = dtoFlash()
      item.UserId = this.$store.state.auth.user_id
      item.Day = this.currentDay
      let newItem = await api2.ChangeFlashStat(item)
      this.flashItems.push(newItem)
    },
    async removeFlashItem() {
      //console.log(this.selectedItems)
      for (const id of this.selectedItems) {
        //console.log('selected',id)
        let item = this.flashItems.find((i) => i.Id === id)
        //console.log('item',item)
        await api2.DeleteFlashStat(item)
        //console.log('delete',result)
        const index = this.flashItems.indexOf(item)
        if (index > -1) {
          // only splice array when item is found
          this.flashItems.splice(index, 1) // 2nd parameter means remove one item only
        }
      }
      this.selectedItems = []
    },
    async changed(item) {
      //console.log(item)
      if (item.selected) {
        this.selectedItems.push(item.Id)
      } else {
        this.selectedItems = this.selectedItems.filter((i) => i !== item.Id)
      }
      //console.log(this.selectedItems)
      await api2.ChangeFlashStat(item)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--reverse {
  margin-top: 6px;
  .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .v-application--is-ltr & {
      .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}
::v-deep .v-alert__content {
  padding: 0px 10px 10px 20px;
}
::v-deep .v-alert__icon {
  margin: 0px;
  min-width: 24px;
  height: 24px;
  &.v-icon {
    font-size: 24px;
  }
}
::v-deep .v-label {
  font-size: 14px;
}
</style>
