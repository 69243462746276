<template>
  <loginform />
</template>

<script>
import loginform from '@/components/LoginForm'

export default {
  components: {
    loginform
  }
}
</script>
