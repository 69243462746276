import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import auth from '@/store/modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    showAppBar: false,
    showSnackBar: false,
    snackBarText: '',
    currentDay: '',
    flashRef: [],
    bigCal: (localStorage.getItem('big_cal')?.toLowerCase() === 'true'),
    flashEnabled: (localStorage.getItem('flash_enabled')?.toLowerCase() === 'true'),
  },
  getters: {
    getField
  },
  mutations: {
    SET_APPBAR (state, payload) {
      state.showAppBar = payload
    },
    SET_SNACKBAR (state, payload) {
      state.showSnackBar = payload
    },
    SET_SNACKBAR_TEXT (state, payload) {
      state.snackBarText = payload
    },
    SET_CURRENTDAY (state, payload) {
      state.currentDay = payload
    },
    updateField,
  },
  actions: {}
})
