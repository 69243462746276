<template>
  <v-container
    fluid
    fill-height
  >
    <small class="version">Version: {{ version }}</small>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-0 pa-2 mb-0">
          <v-card-title class="d-block text-center">
            <div class="mb-10">
              <h1 class="brand">
                MediX
              </h1>
              <small>your medication database</small>
            </div>
            <img
              id="logo-lrg"
              src="@/assets/logo-lrg.png"
              class="mr-2"
              :class="{ loading: loading }"
              @click="clearCache()"
            />
          </v-card-title>
          <v-card-text>
            <v-alert
              v-if="message"
              border="right"
              text
              dense
              color="red"
              type="error"
              elevation="0"
            >
              {{ message }}
            </v-alert>
            <v-form>
              <v-text-field
                v-model="user"
                prepend-inner-icon="mdi-account"
                label="Username"
                type="text"
              />
              <v-text-field
                v-model="password"
                prepend-inner-icon="mdi-lock"
                label="Password"
                type="password"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="d-block text-center">
            <v-btn
              dense
              text
              color="primary"
              @click.stop="logIn()"
            >
              MediX Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    user: '',
    password: '',
    message: '',
    loading: false,
    version: process.env.VUE_APP_VERSION,
  }),
  computed: {
    ...mapState('auth', ['token']),
  },
  methods: {
    ...mapActions('auth', ['login']),
    logIn: function () {
      this.loading = true
      this.login({ user: this.user, password: this.password })
        .then(() => {
          if (this.token) {
            this.$router.push('/day')
          }
        })
        .catch((e) => {
          //console.log(e)
          if (e) {
            if (e.errors) {
              this.message = 'Username and Password required'
            } else {
              this.message = e.message
            }
          } else {
            this.message = '500 Internal Server Error'
          }
          this.loading = false
        })
    },
    clearCache() {
      location.reload(true)
    },
  },

}
</script>

<style scoped>
::v-deep .v-alert__content {
  font-size: 12px;
}
::v-deep .v-card__text {
  padding-bottom: 0px;
}
#logo-lrg {
  margin: auto;
  max-width: 92px;
}
.loading {
  animation: 2s check360 infinite linear;
}
.version {
  position: absolute;
  bottom: 3px;
  left: 5px;
}
@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes check360 {
  to {
    transform: rotate(-360deg);
  }
}

.pulse {
  animation: pulse 1s linear infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
