/**
 * dto_medix
 * @date 2022-03-04
 * @param {number} Id
 * @param {number} UserId
 * @param {string}  Name
 * @param {number}  Dose
 * @param {number}  Shift1
 * @param {number}  Shift2
 * @param {number}  Shift3
 * @param {number}  Order
 * @param {bool}  Alert
 * @param {string}  From
 * @param {string}  To
 * @param {bool}  Pause
 * @param {string}  PauseFrom
 * @param {srting}  Color
 * @returns {object}
 */
export default function dto_medix (Id = 0, UserId = 0, Name = null, Dose = 1, Shift1 = false, Shift2 = false, Shift3 = false, Order = 0, Alert = false, From = '2000-01-01', To = '2100-01-01', Pause = false, PauseFrom = '2100-01-01', Color = '#000') {
    return {
        Id,
        UserId,
        Name,
        Dose,
        Shift1,
        Shift2,
        Shift3,
        Order,
        Alert,
        From,
        To,
        Pause,
        PauseFrom,
        Color
    }
}
