<template>
  <div>   
    <!-- <v-toolbar
      color="white"
      flat    
      class="fixed-bar" 
      dense     
    >
      <v-toolbar-title class="grey--text text--darken-4">
        Medikamente Liste
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        light
        @click="disabled = !disabled"        
      >
        <v-icon 
          v-if="disabled"
          color="grey darken-2"
        >
          mdi-sort-variant-lock
        </v-icon>
        <v-icon 
          v-else
          color="grey darken-2"
        >
          mdi-arrow-all
        </v-icon>
      </v-btn>
    </v-toolbar> -->
    <draggable
      :list="data"    
      :disabled="disabled"      
      @end="endMove"
    >      
      <v-card
        v-for="item in data"
        :key="item.Id"
        class="mb-4"
        :to="link(item.Id)"
        elevation="0"
      >
        <v-card-title>
          <v-icon
            class="mr-2 mt-1"
            :color="item.Color"
            v-text="dose(item.Dose)"
          ></v-icon>
          {{ item.Name }}
          <v-icon
            v-if="item.Alert"
            color="red"
            class="ml-2 mt-1"
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-spacer />
          <v-icon
            v-if="item.Pause"
            color="black"
            class="ml-2 mt-1"
          >
            mdi-pause-circle-outline
          </v-icon>
          <v-icon
            v-if="finished(item.To)"
            color="black"
            class="ml-2 mt-1"
          >
            mdi-flag-checkered
          </v-icon>
        </v-card-title>
        <v-card-text class="d-flex pb-0">
          <v-checkbox
            v-model="item.Shift1"
            class="mr-4"
            label="morgens"
            readonly
          ></v-checkbox>
          <v-checkbox
            v-model="item.Shift2"
            class="mr-4"
            label="mittags"
            readonly
          ></v-checkbox>
          <v-checkbox
            v-model="item.Shift3"
            class="mr-4"
            label="abends"
            readonly
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </draggable>   
    <v-btn
      fab
      fixed
      bottom
      right
      small
      color="primary"
      dark
      @click="disabled = !disabled"        
    >
      <v-icon 
        v-if="disabled"
       
        dark
      >
        mdi-sort-variant-lock
      </v-icon>
      <v-icon 
        v-else
       
        dark
      >
        mdi-arrow-all
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import api from '@/api/medix'

export default {
  name: 'MedCard',
  components: {
    draggable,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        []
      },
    },
  },
  data() {
    return {
      disabled: true
    }
  },
  computed: {
    list() {
      return this.data
    }
  },
  methods: {
    link(id) {
      return '/form/' + id
    },
    dose(d) {
      if (d === 1) return 'mdi-circle-slice-8'
      if (d === 2) return 'mdi-circle-slice-4'
      if (d === 4) return 'mdi-circle-slice-2'
      if (d === 11) return 'mdi-hexagon-slice-6'
      if (d === 12) return 'mdi-hexagon-slice-3'
      if (d === 14) return 'mdi-hexagon-slice-2'
      return 'mdi-circle-outline'
    },
    finished(date) {
      let end = new Date(date)
      let today = new Date()
      return end < today ? true : false
    },
    checkMove(evt){
      console.log(evt.relatedContext.list)
    },
    endMove() {
      //console.log(this.list)
      this.list.forEach((medix, i) => {
        medix.Order = i
      })
      //console.log(this.list)
      Promise.all(
        this.list.map(async medix => {
          await api.ChangeMedix(medix)
        })
      )
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  background-image: linear-gradient(135deg, rgb(229, 229, 229, 0.8), #fff);
}
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
::v-deep .v-label {
  font-size: 14px;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 60px; 
  z-index: 2;
}
</style>
