<template>
  <v-container>
    <v-sheet
      tile
      height="54"
      class="d-flex mt-1"
    >
      <v-btn
        fab
        small
        outlined
        class="mt-0"
        color="rgba(0, 0, 0, 0.4)"
        @click="prevMonth"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-text-field
        v-model="month"
        :items="select"
        label="Monat"
        prepend-inner-icon="mdi-calendar-month"
        outlined
        readonly
        dense
        hide-details
        class="centered-input mx-2 mb-2"
      />

      <v-btn
        fab
        small
        outlined
        class="mt-0"
        color="rgba(0, 0, 0, 0.4)"
        @click="nextMonth"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet
      v-show="!loading"
      v-touch="{
        left: () => nextMonth(),
        right: () => prevMonth(),
      }"
      height="600"
    >
      <v-calendar
        ref="calendar"
        v-model="date"
        :weekdays="weekday"
        :type="type"
        :events="events"
        locale="de-de"
        :event-more="false"
        event-overlap-mode="stack"
        hide-header
        @click:event="showEvent"
        @click:date="dayClicked"
      >
        <template #day="d">
          <div
            v-if="flashEnabled"
            :class="[isFlash(d), { big: bigCal }]"
          >
            <div v-if="isFlash(d) !== 'noflash' && isFlash(d) !== 'nodata'">
              <div class="event">
                <v-icon
                  v-for="(i, index) in getFlashsByDay(d)"
                  :key="index"
                  :color="i.color"
                >
                  {{ i.icon }}
                </v-icon>
              </div>
            </div>
          </div>
          <div
            v-else
            :class="[{ big: bigCal }]"
          ></div>
        </template>
        <template #event="{ event }">
          <div
            v-if="grouped"
            class="event"
          >
            {{ event.name }}
          </div>
          <div
            v-else
            class="event"
          >
            <v-icon
              small
              color="white"
            >
              {{ dose(event.dose) }}
            </v-icon>
            {{ event.name.substring(0, 5) }}
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import api from '@/api/medix'
import api2 from '@/api/flash'

export default {
  data() {
    return {
      date:
        this.$store.state.currentDay ||
        new Date().toISOString().substring(0, 10),
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      grouped: true,
      events: [],
      loading: false,
      flashs: [],
      select: ['Jänner 2023', 'Februar 2023', 'März 2023'],
    }
  },
  computed: {
    ...mapFields(['bigCal', 'flashEnabled']),
    month: {
      get() {
        const month = [
          'Jänner',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ]
        const d = new Date(this.date)
        return month[d.getMonth()] + ' ' + this.date.substring(0, 4)
      },
      set(val) {
        const monthMap = {
          Januar: 0,
          Jänner: 0,
          Februar: 1,
          März: 2,
          April: 3,
          Mai: 4,
          Juni: 5,
          Juli: 6,
          August: 7,
          September: 8,
          Oktober: 9,
          November: 10,
          Dezember: 11,
        }
        const [month, year] = val.split(' ')
        this.date = new Date(Date.UTC(year, monthMap[month], 1))
          .toISOString()
          .substring(0, 10)
        console.log(this.date)
      },
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapMutations(['SET_CURRENTDAY']),
    isFlash(d) {
      if (this.flashs.length === 0) return 'nodata'
      //console.log(d.date,this.flashs)
      let found = this.flashs.find((x) => x.start === d.date)?.intens
      if (found == 3) return 'intens3'
      if (found == 2) return 'intens2'
      if (found == 1) return 'intens1'
      return 'noflash'
    },
    getFlashsByDay(d) {
      let a = this.flashs.filter((x) => x.start === d.date)
      let b = a.map((x) => this.firstLetter(x))
      //console.log(d.date,b)
      if (a.length > 0) return b
      return ''
    },
    dateChanged(val) {
      this.date = val
    },
    dayClicked() {
      //console.log(this.date)
      this.SET_CURRENTDAY(this.date)
      this.$router.push('/day')
    },
    showEvent({ event }) {
      if (this.grouped) {
        this.getDetails(event.name)
      } else {
        this.getData()
      }
    },
    async nextMonth() {
      console.log(this.date)
      this.$refs.calendar.next()
      await this.getData()
    },
    async prevMonth() {
      console.log(this.date)
      this.$refs.calendar.prev()
      await this.getData()
    },
    async getData() {
      ////console.log(this.date)
      this.loading = true
      this.grouped = true
      this.flashs = await api2.GetMonthGrouped(this.date)
      this.events = await api.GetMonthGrouped(this.date)
      //console.log('getdata',this.flashs)
      this.loading = false
    },
    async getDetails(med) {
      this.loading = true
      let medName = med.substring(3, 7)
      //console.log(med, medName)
      this.grouped = false
      this.events = await api.GetMonthWithDose(this.date)
      this.events = this.events.filter(({ name }) => name === medName)
      this.loading = false
    },
    dose(d) {
      if (d === 1) return 'mdi-circle-slice-8'
      if (d === 2) return 'mdi-circle-slice-4'
      if (d === 4) return 'mdi-circle-slice-2'
      if (d === 11) return 'mdi-hexagon-slice-6'
      if (d === 12) return 'mdi-hexagon-slice-3'
      if (d === 14) return 'mdi-hexagon-slice-2'
      return 'mdi-circle-outline'
    },
    firstLetter(item) {
      return {
        icon:
          'mdi-alpha-' + item.name.charAt(0).toLowerCase() + '-circle-outline',
        color: item.color,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .sticky-header {
  position: sticky;
  top: 0;
}

::v-deep .v-calendar-weekly__week {
  .v-calendar-weekly__day-label {
    margin-top: 0px;
  }
  .v-btn--fab.v-size--small {
    height: 20px;
    width: 20px;
  }
}

::v-deep .v-event {
  margin-left: 2px;
}

.event {
  padding: 0 3px;
  font-size: 10px;
}
.event ::v-deep .v-icon {
  font-size: 14px !important;
}
th {
  font-size: 12px;
  padding: 0;
}
th:first-of-type {
  text-align: left !important;
}

::v-deep .v-calendar-weekly__day:has(.intens1) {
  background: lighten(lime, 45%);
}

::v-deep .v-calendar-weekly__day:has(.intens2) {
  background: lighten(orange, 40%);
}

::v-deep .v-calendar-weekly__day:has(.intens3) {
  background: lighten(red, 40%);
}

::v-deep .v-calendar-weekly__week:has(.big) {
  min-height: 170px;
}

.event__border {
  border: 1px solid black;
  height: 20px;
  width: 95%;
  margin-bottom: 1px;
}

.intens1,
.intens2,
.intens3 {
  display: block;
  position: absolute;
  bottom: 0px;
  .v-icon {
    font-size: 14px !important;
  }
}
</style>
