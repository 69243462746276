<template>
  <v-app>
    <v-app-bar
      v-if="showAppBar"
      dark
      color="primary"
      app
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-1 brand mt-1 ml-n3">
        MediX
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        to="/day?today=true"
      >
        <v-icon> mdi-calendar-today </v-icon>
      </v-btn>
      <v-btn
        icon
        to="/calendar"
      >
        <v-icon>mdi-calendar-month</v-icon>
      </v-btn>
      <v-btn
        icon
        to="/meds"
      >
        <v-icon>mdi-format-list-text</v-icon>
      </v-btn>
      <v-btn
        icon
        to="/flashs"
      >
        <v-icon>mdi-format-list-bulleted-type</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      width="270"
    >
      <v-list
        id="userarea"
        color="primary"
      >
        <v-list-item
          two-line
          dark
        >
          <v-btn
            fab
            elevation="0"
            color="primary"
            class="mr-2"
          >
            <img
              id="avatar-img"
              src="@/assets/profil_switch_dark.png"
              @click="switchUserID"
            />
          </v-btn>

          <v-list-item-content>
            <v-list-item-title>{{ username }}</v-list-item-title>
            <v-list-item-subtitle>
              <small>{{ profName }}</small>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list
        nav
        dense
      >
        <v-subheader>App Menü</v-subheader>
        <v-list-item
          v-for="item in menu.items"
          :key="item.id"
          :to="item.link"
          @click="() => {}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list
        id="app-settings"
        dense
        light
      >
        <v-subheader>App Settings</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-switch
              v-model="bigCal"
              dense
              label="Kalendar großer Abstand"
              hide-details
              @change="calChanged"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-switch
              v-model="flashEnabled"
              dense
              label="Tagebuch"
              hide-details
              @change="flashChanged"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          id="version"
          @click="refreshToken"
        >
          <small>Version: {{ version }}</small>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <v-snackbar
      v-model="showSnackBar"
      timeout="-1"
      color="error"
    >
      {{ snackBarText }}

      <template #action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          v-bind="attrs"
          @click="hideSnackBar()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import menu from '@/mockdata/menu'
import { encrypt } from '@/services/crypto'
import { primProf, secProf } from '@/common/themes'

export default {
  name: 'App',
  // components: {
  //   navbar,
  //   appbar
  // }
  data() {
    return {
      menu: menu,
      calSize: true,
      profName: 'Primärprofile',
      currProf: primProf(),
      drawer: false,
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    ...mapState(['showAppBar', 'showSnackBar', 'snackBarText']),
    ...mapState('auth', ['expires']),
    ...mapFields(['bigCal', 'flashEnabled']),
    ...mapFields('auth', ['user_id', 'profiles']),
    username() {
      return this.$store.state.auth.user_name
    },
    sessionTimeout() {
      let date = new Date(this.expires * 1000)
      //let day = date.toLocaleDateString('de-at')
      let time = date.toLocaleTimeString('de-at')
      return time
    },
  },
  mounted() {
    this.switchProfile()
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'SET_SNACKBAR_TEXT']),
    ...mapActions('auth', ['refreshToken']),
    hideSnackBar() {
      this.SET_SNACKBAR(false)
    },
    calChanged() {
      localStorage.setItem('big_cal', this.bigCal)
    },
    flashChanged() {
      localStorage.setItem('flash_enabled', this.flashEnabled)
    },
    switchUserID() {
      let newID = this.profiles.find((x) => x.Key != this.user_id)?.Key
      //console.log('newID',newID)
      if (!newID) {
        this.SET_SNACKBAR(true)
        this.SET_SNACKBAR_TEXT('Noch kein Sekundärprofile definiert')
        return
      }
      this.user_id = newID
      localStorage.setItem('user_id', encrypt(this.user_id))
      location.reload(true)
    },
    switchProfile() {
      let newID = this.profiles.find((x) => x.Key == this.user_id)
      if (newID) {
        switch (newID.Value) {
        case 'Sekundärprofil':
          this.currProf = secProf()
          this.profName = newID.Value
          break

        default:
          this.currProf = primProf()
          this.profName = newID.Value
          break
        }
        localStorage.setItem('color_prof', JSON.stringify(this.currProf))
        this.$vuetify.theme.themes['light'] = {
          ...this.$vuetify.theme.themes['light'],
          ...this.currProf,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#home::before,
#home::before {
  opacity: 0 !important;
}
#version {
  position: absolute;
  bottom: 0;
  left: 0;
}
//#userarea {
  // background-image: linear-gradient(135deg, rgba(229, 229, 229, 0.8), #fff);
  // background-color: #009688;
//}
#app-settings div {
  font-size: 12px;
}
#app-settings ::v-deep .v-label {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
#app-settings ::v-deep .v-input--selection-controls.v-input {
  margin-top: 0px;
}

#avatar-img {
  width: 40px;
  height: 40px;
}

// #avatar-img:hover {
//   background-color: #00b4a2;
// }
</style>
