<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        <span class="d-flex">
          <v-text-field
            v-model="med.Name"
            label="Name"
            placeholder="Name"
            class="grow"
          />
          <v-text-field
            v-model="med.Color"
            label="Farbe"
            placeholder="#FFFFFF"
            class="ml-4"
          />
          <v-icon
            class="ml-n6"
            :color="med.Color"
          >mdi-square</v-icon>
        </span>
        <v-switch v-model="med.Pause">
          <template #label>
            Medikament Pausieren
          </template>
        </v-switch>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-switch v-model="med.Alert">
          <template #label>
            muss genommen werden
            <v-icon
              class="ml-2"
              color="red"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
        </v-switch>
        <span class="d-flex">
          <v-icon class="mr-2 mb-1">mdi-weather-sunset-up</v-icon>
          <v-checkbox
            v-model="med.Shift1"
            class="mr-4"
            label="morgens"
          ></v-checkbox>
        </span>
        <span class="d-flex">
          <v-icon class="mr-2 mb-1">mdi-weather-sunny</v-icon>
          <v-checkbox
            v-model="med.Shift2"
            class="mr-4"
            label="mittags"
          ></v-checkbox>
        </span>
        <span class="d-flex">
          <v-icon class="mr-2 mb-1">mdi-weather-night</v-icon>
          <v-checkbox
            v-model="med.Shift3"
            class="mr-4"
            label="abends"
          ></v-checkbox>
        </span>
        <div class="mt-2">
          Dosis:
        </div>
        <v-radio-group
          id="radio"
          v-model="med.Dose"
          row
          dense
          hide-details
          class="mt-0"
        >
          <v-radio
            label="¼"
            :value="4"
            hide-details
            dense
          >
          </v-radio>
          <v-radio
            label="½"
            :value="2"
            hide-details
            dense
          >
          </v-radio>
          <v-radio
            label="1"
            :value="1"
            hide-details
            dense
          >
          </v-radio>
          <v-radio
            label="1¼"
            :value="14"
            hide-details
            dense
          >
          </v-radio>
          <v-radio
            label="1½"
            :value="12"
            hide-details
            dense
          >
          </v-radio>
          <v-radio
            label="2"
            :value="11"
            hide-details
            dense
          >
          </v-radio>
        </v-radio-group>
        <span class="d-flex mt-8 mx-n2">
          <span class="">
            <day-picker
              :key="componentKey"
              :day="fromDate"
              placeholder="DD.MM.YYYY"
              label="Von"
              :readonly="true"
              :heute="true"
              @changed="fromDateChanged"
            /></span>
          <span class="">
            <day-picker
              :key="componentKey"
              :day="toDate"
              placeholder="DD.MM.YYYY"
              label="Bis"
              :readonly="true"
              :heute="true"
              @changed="toDateChanged"
            /></span>
        </span>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          to="/meds"
        >
          zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text>
          löschen
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/api/medix'
import dtoMedix from '@/models/dtos/dto_medix'
import DayPicker from '@/components/DayPicker.vue'

export default {
  components: { DayPicker },
  data: () => ({
    dose: 1,
    med: dtoMedix(),
    componentKey: 0,
  }),
  computed: {
    fromDate() {
      return this.med.From.substring(0, 10)
    },
    toDate() {
      return this.med.To.substring(0, 10)
    },
  },
  mounted() {
    //console.log('before', this.med)
    this.getMed()
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'SET_SNACKBAR_TEXT']),
    async getMed() {
      let medID = this.$route.params.id
      if (medID > 0) {
        this.med = await api.GetSingle(medID)
        this.forceRerender()
        //console.log('after', this.med)
      } else {
        this.med = dtoMedix()
        this.med.UserId = parseInt(this.$store.state.auth.user_id)
        //console.log('after and new', this.med)
      }
    },
    async save() {
      //console.log(this.med)
      try {
        if (this.med.Pause) {
          this.med.PauseFrom = new Date().toISOString().substring(0, 10)
        }
        await api.ChangeMedix(this.med)
        //console.log(result)
        this.$router.push('/meds')
      } catch (error) {
        //console.log(error)
        this.SET_SNACKBAR(true)
        this.SET_SNACKBAR_TEXT(error.title)
      }
    },
    fromDateChanged(val) {
      this.med.From = val
    },
    toDateChanged(val) {
      this.med.To = val
    },
    forceRerender() {
      this.componentKey += 1
      //console.log('rerendered')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
::v-deep #radio .v-input--selection-controls__input {
  margin-right: 1px;
}
::v-deep .v-card__title {
  background-image: linear-gradient(135deg, rgb(229, 229, 229, 0.8), #fff);
}
.v-radio {
  margin-right: 8px !important;
}
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
